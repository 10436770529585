/**
 * Keyboard handler for lists of elements that a user can iterate over.
 *
 * Whenever you have a list of elements you want to allow users to iterate over, add the "iterable" class to them. This
 * class will manage the keyboard events needed to move over the list with the <up> and <down> arrows, as well as
 * perform an action on the current element, depending on its type, whenever <enter> or <space> are pressed with that
 * element focused. Currently, the following actions are allowed:
 *
 * - Follow a link. Just add the "iterable" class to an "a" element.
 *
 * - Expand/collapse an "expandable" element. Add the "iterable" class to a "div" element with the "expandable" class.
 *   In this case, the handler will trigger a "click" event on the "expander" child of the selected element. Add also
 *   the desired tab index for the "div" element to a "data-index" property. Do not add "tabindex" properties directly
 *   to divs, as that will allow them to get focus even when javascript is disabled, but in that case no action can be
 *   performed on them!
 *
 * Finally, users can press <esc> to clear the current selection. When the selection is cleared, pressing <up> or <down>
 * gets you back to the bottom or the top of the list, respectively.
 *
 * This script can be safely included on templates. It will only work when at least an element with the "iterable" class
 * is present in the page.
 *
 * Only a single list of elements is allowed. Currently, it is not possible to have several lists of "iterable" elements
 * that can be iterated independently.
 *
 * Bear in mind that this script captures keys pressed, mouse movement and focus changes.
 */
class Iterator {
    private readonly iterable: JQuery<HTMLElement>;
    private readonly selectize: Selectize.IApi<any, any> | undefined;
    private selected?: JQuery<HTMLElement>;
    private hovered?: JQuery<HTMLElement>;
    private lastKey: number;

    constructor(iterable: JQuery<HTMLElement>, selectize?: Selectize.IApi<any, any>) {
        this.iterable = iterable;
        this.selectize = selectize;
        this.selected = undefined;
        this.hovered = $('.iterable:hover');
        this.lastKey = 0;

        // js is supported, see if we need to add tab indices
        iterable.each(function insertTabIndices() {
            if ($(this).attr('tabindex')) {
                return;
            }
            const index = $(this).data('index');
            if (index) {
                $(this).attr('tabindex', index);
            }
        });

        if (this.hovered.length) {
            // mouse already hovering over an element, mark it as selected
            this.selected = this.hovered;
        }

        // add a custom handler when any of the options gets focus
        this.iterable
            .on('focusin', (event) => this.focusInHandler.bind(this, event)())
            .on('focusout', (event) => this.focusOutHandler.bind(this, event)())
            // add custom handlers to capture when the mouse hovers over iterable options
            .on('mouseenter', (event) => this.mouseEnterHandler.bind(this, event)())
            .on('mouseleave', (event) => this.mouseLeaveHandler.bind(this, event)())
            .on('mousemove', (event) => this.mouseMoveHandler.bind(this, event)());

        // add a custom handler for keyboard events
        $(document).keydown((event) => this.keyDownHandler.bind(this, event)());
    }

    /**
     *
     * @param event
     */
    focusInHandler(event: JQuery.FocusInEvent) {
        const element = $(event.target);
        element.removeClass('unfocused').addClass('focused');

        if (this.selected && !element.is(this.selected)) {
            this.selected.children('a,button').focusout();
        }
        this.selected = element;
    }

    /**
     *
     * @param event
     */
    focusOutHandler(event: JQuery.FocusOutEvent) {
        const element = $(event.target);
        element.removeClass('focused').addClass('unfocused');

        if (this.selected && element.is(this.selected)) {
            this.selected = undefined;
        }

        if (element.is(this.iterable.last()) && this.lastKey === 9) {
            // we are tab-iterating out of the last element of the list, clear selection
            this.selected = undefined;
        }
    }

    /**
     *
     * @param event
     */
    mouseEnterHandler(event: JQuery.MouseEnterEvent) {
        event.stopPropagation();
        let element = $(event.target);
        if (this.iterable.filter(element).length === 0) {
            element = element.closest('.list-group-item');
        }

        this.selected = element;
        this.selected.removeClass('unfocused');
        this.hovered = this.selected;
        this.hovered.focus();
    }

    /**
     *
     * @param event
     */
    mouseLeaveHandler(event: JQuery.MouseLeaveEvent) {
        event.stopPropagation();
        if (this.selected) {
            this.selected.removeClass('focused unfocused');
            this.selected.focusout();
            this.selected = undefined;
            this.hovered = undefined;
        }
    }

    /**
     *
     * @param event
     */
    mouseMoveHandler(event: JQuery.MouseMoveEvent) {
        let element = $(event.target);
        if (this.iterable.filter(element).length === 0) {
            element = element.closest('.list-group-item');
        }

        this.selected = element;
        this.hovered = this.selected;
        this.hovered.focus();
    }

    /**
     *
     * @param event
     */
    keyDownHandler(event: JQuery.KeyDownEvent) {
        // @ts-ignore: Selectize haven't defined isOpen in their type,
        // even thought it exists
        if (this.selectize?.isOpen) {
            // selectize is open, stop!
            return;
        }
        const focusedButton = $('button:focus');
        this.selected = this.iterable.filter('.focused');

        if (this.hovered?.length && !this.hovered.hasClass('unfocused') && !this.selected.length) {
            // if the mouse is hovering over an option and there's no other option selected, mark it as selected
            this.selected = this.hovered;
        }

        // record last key pressed
        this.lastKey = event.keyCode;

        const { keyCode } = event;
        if (keyCode === 40) { // Down arrow
            let next = this.iterable.first();
            if (this.selected.length) {
                this.selected.removeClass('focused').addClass('unfocused');
                if (this.selected.next().length) {
                    next = this.selected.next();
                }
            }

            next.focus();
            this.selected = next;
            const selectedElement = this.selected.get(0);
            selectedElement?.scrollIntoView();
        } else if (keyCode === 38) { // Up arrow
            let prev = this.iterable.last();
            if (this.selected.length) {
                this.selected.removeClass('focused').addClass('unfocused');
                if (this.selected.prev().length) {
                    prev = this.selected.prev();
                }
            }
            prev.focus();
            this.selected = prev;
            const selectedElement = this.selected.get(0);
            selectedElement?.scrollIntoView();
        } else if (keyCode === 32 || keyCode === 13) { // Space or enter
            if (!this.selected.length || focusedButton.length) {
                return;
            }
            event.preventDefault();

            const anchor = this.selected.children('a');
            if (anchor.length) {
                window.location.href = anchor.attr('href')!;
                return;
            }
            const selectedElement = this.selected.get(0);
            selectedElement?.scrollIntoView();
            this.selected.children('.expander').trigger('click');
        } else if (keyCode === 27) { // Escape
            if (this.selected.length) {
                this.selected.focusout();
            }
        }
    }
}

export default Iterator;
