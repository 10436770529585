import 'core-js/stable/promise';
import 'core-js/stable/array/find';
import 'core-js/stable/string/includes';
import 'selectize/dist/js/selectize';
import Iterator from './Iterator';
import { SelectizeEscapeHandler, SelectizeItem } from './SelectizeTypes';

class Common {
    private readonly page: JQuery<HTMLElement>;
    private readonly resources: string;
    private readonly toggleButton: JQuery<HTMLElement>;
    private readonly langSelectize?: Selectize.IApi<any, any> = undefined;

    constructor() {
        this.page = $('body');
        this.resources = JSON.parse(this.page.data('assets-dir'));
        this.toggleButton = $('.toggle-button');

        const langChooser = $('#language-chooser');
        if (langChooser.length !== 0) {
            $('.language-chooser input').prop('readonly', 'true'); // Disable search
            $('#language-chooser-wrapper').removeClass('not-visible');
        }

        function showLanguageDropdown() {
            $('.lang-dropdown-button').attr('aria-expanded', 'true');
            $('.lang-dropdown-button').removeClass('closed');
            $('.lang-dropdown-button').addClass('open');
            $('.lang-dropdown-list').css('display', 'block');
            $('.lang-dropdown-lang').first().focus();
        }

        function hideLanguageDropdown() {
            $('.lang-dropdown-button').attr('aria-expanded', 'false');
            $('.lang-dropdown-button').removeClass('open');
            $('.lang-dropdown-button').addClass('closed');
            $('.lang-dropdown-list').css('display', 'none');
        }

        $('.lang-dropdown-lang').on('click', function chooseLanguage() {
            // chosenLang will never be missing when it is together with .lang-dropdown-lang
            const chosenLang = $(this).attr('lang')!;
            $('#language-chooser').val(chosenLang);
            $('#language_selector').submit();
        });

        $('.lang-dropdown-lang').on('mouseenter', function focusOnLanguageDropdown() {
            $(this).focus();
        });

        $('.lang-dropdown-button').on('keydown', function toggleLanguageDropdown(event) {
            if (event.keyCode === 13) {
                if ($(this).attr('aria-expanded') === 'false') {
                    showLanguageDropdown();
                } else {
                    hideLanguageDropdown();
                }
            }
        });

        $('.lang-dropdown-button').on('click', function toggleLanguageDropdown(event) {
            event.stopPropagation();
            if ($(this).attr('aria-expanded') === 'false') {
                showLanguageDropdown();
            } else {
                hideLanguageDropdown();
            }
        });

        $('.lang-dropdown-lang').on('keydown', function navigateLanguageDropdown(event) {
            if (event.keyCode === 13) {
                // chosenLang will never be missing when it is together with .lang-dropdown-lang
                const chosenLang = $(this).attr('lang')!;
                $('#language-chooser').val(chosenLang);
                $('#language_selector').submit();
            } else if (event.keyCode === 38) { // Up arrow
                event.preventDefault();
                $(this).prev().focus();
            } else if (event.keyCode === 40) { // Down arrow
                event.preventDefault();
                $(this).next().focus();
            } else if (event.keyCode === 27) { // Escape
                $('.lang-dropdown-button').focus();
                hideLanguageDropdown();
            }
        });

        $(window).on('click', (eve) => {
            hideLanguageDropdown();
            const clName = ((eve.target as unknown) as Element).className;
            const idName = ((eve.target as unknown) as Element).id;
            if (!clName.includes('more-info') && $('#more-information-tooltip').css('display') !== 'none') {
                $('#more-information-tooltip').css('display', 'none');
                $('#more-info-tooltip-button').attr('aria-expanded', 'false');
            }
            if (!clName.includes('orglist') && idName !== 'org_selector_filter') {
                $('#orglist').css('display', 'none');
                $('#orglist_wrapper').css('overflow-y', 'hidden');
            }
        });

        $('.lang-dropdown-list').on('click', (event) => {
            event.stopPropagation();
        });

        $('.panel.expandable .panel-heading').on('click', function togglePanelExpansion() {
            const heading = $(this);
            const panel = heading.parent();
            panel.toggleClass('expanded');
            const expanded = panel.hasClass('expanded');
            heading.find('.toggle-button').attr('aria-expanded', expanded.toString());
            panel.find('.panel-content').attr('aria-hidden', (!expanded).toString());
        });

        $('.panel.dismissible i.icon').on('click', () => {
            $(this).parent('.panel.dismissible').hide();
        });

        function toggleMoreInfo() {
            if ($('#more-information-tooltip').css('display') === 'none') {
                $('#more-information-tooltip').css('display', 'block');
                $('#more-info-tooltip-button').attr('aria-expanded', 'true');
                $('#more-info-tooltip-button').attr('aria-live', 'polite');
                $('#tooltip-text').attr('tabindex', 0);
            } else {
                $('#more-information-tooltip').css('display', 'none');
                $('#more-info-tooltip-button').attr('aria-expanded', 'false');
            }
        }

        $('#more-info-tooltip-button').on('click', () => {
            toggleMoreInfo();
        });

        $('.affiliation-tooltip').on('click', () => {
            toggleMoreInfo();
        });

        $('.tooltip').on('click', function toggleTooltip() {
            const toggle = this;
            const ariaControls = this.getAttribute('aria-controls');
            if (!ariaControls) {
                // We couldn't find a tooltip that aria-controls
                return;
            }
            const target = document.getElementById(ariaControls)!;
            const targetButton = document.getElementById('affiliation-info-button');

            if (target.getAttribute('aria-hidden') === 'true') {
                /* Timer is used to make sure the population of the target
                   element is noticed by all setups */
                setTimeout(() => {
                    target.setAttribute('aria-hidden', 'false');
                    target.setAttribute('aria-expanded', 'true');
                    targetButton?.setAttribute('aria-expanded', 'true');
                    target.innerHTML = toggle.getAttribute('data-toggletip-content') ?? '';
                }, 100);
            } else {
                Common.hideTooltip(target);
            }
        });

        $('.tooltip-bubble').on('click', function hideTooltip() {
            Common.hideTooltip(this);
        });

        $('.backlink').each((index, element) => {
            const $element = $(element);
            if (window.history.length > 1) {
                $element.show();
                $element.on('click', (event) => {
                    event.preventDefault();
                    window.history.back();
                });
            }
        });

        // control focus style for expandable panels
        this.toggleButton.on('focus', (event) => {
            $(event.target).closest('.panel').addClass('focused');
        });
        this.toggleButton.on('blur', (event) => {
            $(event.target).closest('.panel').removeClass('focused');
        });

        // support iterable lists
        const iterable = $('.iterable');
        if (iterable.length) {
            // eslint-disable-next-line no-new
            new Iterator(iterable, this.langSelectize);
        }

        // Autofocus for non-input elements
        $('.autofocus').focus();
    }

    /**
     *  Gets list item element for language dropdown
     */
    static getListItem(type: string, item: SelectizeItem, escape: SelectizeEscapeHandler) {
        const flag = `icon-flag-${item.value}`;
        const text = escape(item.text);
        const lang = escape(item.value);
        return `<div class="${type}">
            <div class="flag-wrapper" lang="${lang}">
                <i class="flag ${flag}"></i> ${text}
            </div>
        </div>`;
    }

    /**
     * Tooltips
     * Toggles target element on click.
     */
    static hideTooltip(tooltip: HTMLElement) {
        const targetButton = document.getElementById('affiliation-info-button');
        targetButton?.setAttribute('aria-expanded', 'false');
        tooltip.setAttribute('aria-hidden', 'true');
        tooltip.setAttribute('aria-expanded', 'false');
        tooltip.innerHTML = '';
    }
}
export default Common;
